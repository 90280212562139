/** @format */
const headerObj = {
  Accept: "application/json",
  "Content-Type": "application/json"
}

const getBaseUrl = (isApi = true) => {
  const { protocol, hostname, port } = window.location
  const apiSuffix = isApi ? "/api/v1" : ""
  const host = _isLocalHost() && isApi ? "localhost:3001" : hostname
  const portString = _isLocalHost() && isApi ? "" : `:${port}`
  return `${protocol}//${host}${portString}${apiSuffix}`
}

const _isLocalHost = () => {
  const hostname = window.location.hostname
  return hostname.match(/local/) !== null
}

const statusCheck = async response => {
  if (!response.ok) {
    const errorInfo = await response.json()
    const { status, statusText } = response
    return Promise.reject({ status, statusText, errorInfo })
  } else {
    return response.json()
  }
}

const getCfgInfo = () => {
  const req = new XMLHttpRequest()
  req.open("GET", `${getBaseUrl()}/util/cfg`, false)
  req.send(null)
  if (req.status === 200) {
    return JSON.parse(req.responseText)
  }
  return null
}

const locInfoUpd = (lat, lon) => {
  const req = new XMLHttpRequest()
  req.open("POST", `${getBaseUrl()}/util/updLoc`, false)
  req.setRequestHeader("Accept", "application/json")
  req.setRequestHeader("Content-Type", "application/json")
  req.send(JSON.stringify({ lat: Number(lat), lon: Number(lon) }))
  return null
}

const locInfo = pingid => {
  const req = new XMLHttpRequest()
  req.open("POST", `${getBaseUrl()}/map/getLocation`, false)
  req.setRequestHeader("Accept", "application/json")
    req.setRequestHeader("Content-Type", "application/json")
    try{
	req.send(JSON.stringify({ pingTypeId: Number(pingid) }))
	if (req.status === 200) {
	    return JSON.parse(req.responseText)
	}
    } catch(exception){
	if(exception.name == 'NetworkError'){
	    window.location.reload(true)
	}
    }
  return null
}

const toggleMyInfo = () => {
  const req = new XMLHttpRequest()
  req.open("GET", `${getBaseUrl()}/util/myInfo`, false)
  req.setRequestHeader("Accept", "application/json")
  req.setRequestHeader("Content-Type", "application/json")
  req.send()
  if (req.status === 200) {
    return
  }
}

const createPing = pingTypeId => {
  return fetch(`${getBaseUrl()}/pings/create`, {
    method: "POST",
    headers: headerObj,
    body: JSON.stringify({
      pingTypeId: pingTypeId
    })
  })
    .then(statusCheck)
    .catch(() => {})
}

const getPings = (selat, selon, nwlat, nwlon) => {
  let remoteUrl = "showPings"
  return fetch(`${getBaseUrl()}/map/` + remoteUrl, {
    method: "POST",
    headers: headerObj,
    body: JSON.stringify({
      southEastPoint: { lat: Number(selat), lon: Number(selon) },
      northWestPoint: { lat: Number(nwlat), lon: Number(nwlon) }
    })
  })
    .then(statusCheck)
    .catch(() => {})
}

const getPingFeed = (stidx, spidx) => {
  let remoteUrl = "liveFeed"
    return fetch(`${getBaseUrl()}/pings/` + remoteUrl, {
    method: "POST",
    headers: headerObj,
    body: JSON.stringify({
        startIndex: Number(stidx),
        stopIndex: Number(spidx) 
  })
    }).then(statusCheck)
    .catch(() => {})
}

const isLoggedIn = () => {
  return fetch(`${getBaseUrl()}/users/isLoggedIn`)
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => {})
}

const getTotalPingCount = () => {
  let remoteUrl = "totalPingCount"
  return fetch(`${getBaseUrl()}/pings/` + remoteUrl, {
    method: "POST",
    headers: headerObj
  })
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => {})
}

const getLocation = () => {
  return fetch(`${getBaseUrl()}/users/me`)
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => {})
}


export {
  locInfo,
  getPings,
  locInfoUpd,
  isLoggedIn,
  createPing,
  getCfgInfo,
  getBaseUrl,
  getLocation,
  getPingFeed,
  toggleMyInfo,
  getTotalPingCount
}
